import React from 'react'


const SoundOffIcon = (props) =>  
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 241.94 219.46"
    {...props}
  >
    <path 
      d="M116.58,56.94a6.53,6.53,0,0,0-9.19-.92L76.64,81a4.58,4.58,0,0,0-.53,0H58.24a6.56,6.56,0,0,0-6.55,6.55v44.42a6.55,6.55,0,0,0,6.55,6.54H76.11l.53,0,30.73,25a6.58,6.58,0,0,0,10.69-5.11V61.08A6.52,6.52,0,0,0,116.58,56.94Zm-42.77,72.7H60.54V89.82H73.81Zm35.4,23.9L82.66,132V87.5l26.55-21.57Z"
    />
    <polygon 
      points="190.25 89.23 183.89 82.86 163.11 103.64 142.33 82.86 135.97 89.23 156.75 110.01 135.97 130.79 142.33 137.15 163.11 116.37 183.89 137.15 190.25 130.79 169.47 110.01 190.25 89.23"
    />
  </svg>

export default SoundOffIcon
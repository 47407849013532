import React from 'react'

const PlayIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 241.94 219.46"
    {...props}
  >
    <polygon
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLineJoin: 'round',
        strokeWidth: 9,
      }}
      points="94.28 160.27 94.28 59.75 159.63 110.01 94.28 160.27"
    />
  </svg>
)

export default PlayIcon

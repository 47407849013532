import React, { Component } from 'react'
import VimeoPlayer from '@vimeo/player'
import { PlayerRoot, PlayerFullscreenBackground } from './Player.styles'
import { uniqueId, bind, noop } from 'lodash-es'
import { VimeoVideoProvider } from '../VideoProvider/VimeoVideoProvider'

export class Player extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fullscreen: false,
        }

        this.tag = props.tag || uniqueId()

        this.debug = true
            ? bind(console.log, console, `[Player]${props.tag ? `[${props.tag}]` : ''}`)
            : noop

        this.videoElement = null
        this.player = null
    }

    setFullscreen(fullscreen) {
        if (fullscreen) {
            setTimeout(() => this.videoElement.requestFullscreen(), 150)
        } else {
        }
        this.setState({ fullscreen })
    }

    setVideoElement(ref) {
        if (!this.videoElement) {
            this.videoElement = ref

            this.initializeVideo()
        }
    }

    onVideoPlayEvent(event) {
        const { onPlay = noop } = this.props
        onPlay()
    }

    onVideoPauseEvent(event) {
        const { onPause = noop } = this.props
        onPause()
    }

    initializeVideo() {
        try {
            const videoId = `player-video-${uniqueId()}`
            this.videoElement.id = videoId

            this.player = new VimeoPlayer(this.videoElement.id)
            this.player.on('play', this.onVideoPlayEvent.bind(this))
            this.player.on('pause', this.onVideoPauseEvent.bind(this))

            this.player.on('loaded', this.onVideoLoadedEvent.bind(this))
        } catch (e) {
            console.error('Error initializing player', e)
        }
    }

    async onVideoLoadedEvent() {
        try {
            if (this.props.autoplay) {
                const intervalId = setInterval(async () => {
                    try {
                        const paused = await this.player.getPaused()
                        if (paused) {
                            await this.player.play()
                            clearInterval(intervalId)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }, 500)
            }
        } catch (e) {
            console.log(e)
        }
    }

    requestFullscreen() {
        this.setFullscreen(true)
    }

    playInFullscreen() {
        this.play()
        this.setVolume(1)
        this.requestFullscreen()
    }

    exitFullscreen() {
        this.pause()
        this.setFullscreen(false)
    }

    onFullScreenChange(event) {
        const { fullscreen } = this.state
        if (fullscreen && !document.fullscreenElement) {
            this.exitFullscreen()
        }
    }

    componentDidMount() {
        this.onFullScreenChange = this.onFullScreenChange.bind(this)
        document.addEventListener('fullscreenchange', this.onFullScreenChange)
    }

    componentWillUnmount() {
        document.removeEventListener('fullscreenchange', this.onFullScreenChange)

        if (this.player) {
            this.player.off('play')
            this.player.off('pause')
        }
    }

    play() {
        this.debug('Play')
        this.player.setVolume(1)
        return this.player.play()
    }

    pause() {
        this.debug('Pause')
        this.player.setVolume(0)
        return this.player.pause()
    }

    setVolume(value) {
        this.debug('Set volume', value * 100 + '%')
        this.player.setVolume(value)
    }

    setMute(mute) {
        this.debug('Mute')
        this.player.setVolume(mute ? 0 : 1)
    }

    render() {
        const {
            aspectRatio = 16 / 9,
            vimeoId,
            background,
            autoplay,
            muted,
            looped,
            allowFullscreen,
            className,
            fillParent,
        } = this.props
        const { fullscreen } = this.state

        return (
            <PlayerRoot {...{ className, aspectRatio, background, fillParent }}>
                <VimeoVideoProvider
                    ref={(ref) => this.setVideoElement(ref)}
                    vimeoId={vimeoId}
                    {...{ background, autoplay, muted, looped, allowFullscreen }}
                    fillParent={fillParent}
                />
                {allowFullscreen && <PlayerFullscreenBackground fullscreen={fullscreen} />}
            </PlayerRoot>
        )
    }
}

import React from 'react'
import styled from 'styled-components'

import Section from '../../components/section'

import { Grid, Cell } from '../../components/grid'
import { Body1, Body2, Heading4 } from '../../components/Text'

import { get, identity, pick, isEmpty, kebabCase, mapValues } from 'lodash-es'

import { MARGINS } from '../../theme'
import { mobileQuery, desktopQuery } from '@src/utils/responsive'
import { useResponsiveContext } from '../../utils/responsive'

const TextSectionTitle = styled(Body1)`
    @media ${desktopQuery} {
        margin-bottom: 6.9rem;
    }
`

const BaseTextSection = ({ className, title, marginTop, marginBottom, children, style }) => (
    <Section
        className={className}
        {...{ marginTop, marginBottom }}
        style={style}
        animation="fade-in-up"
    >
        {!isEmpty(title) && <TextSectionTitle>{title}</TextSectionTitle>}

        {children}
    </Section>
)

const OneCopySection = styled(({ className, text, position }) => {
    return (
        <div className={className}>
            <Grid align={position}>
                <Cell md={6}>
                    <Body2 collapseMargin style={{ marginLeft: '5.2rem' }}>
                        {text}
                    </Body2>
                </Cell>
            </Grid>
        </div>
    )
})``

const OneBigCopySectionBody = styled(Body2)`
    @media ${desktopQuery} {
        padding-right: 10rem;
    }
`

const OneBigCopySection = ({ className, text, position }) => (
    <div className={className}>
        <Grid align={position}>
            <Cell md={9}>
                <OneBigCopySectionBody>{text}</OneBigCopySectionBody>
            </Cell>
        </Grid>
    </div>
)

const TwoBigCopiesSection = ({ className, text, text2, title }) => (
    <div className={className}>
        <Body2>{text}</Body2>

        <Body2>{text2}</Body2>
    </div>
)

const OneCopyAndThreeColumnsLeftText = styled(Body1)`
    @media ${mobileQuery} {
        margin-bottom: 0.7rem;
    }

    @media ${desktopQuery} {
        padding-right: 38rem;
    }
`

const OneCopyAndThreeColumns = (props) => {
    const columnTexts = [props.text2, props.text3, props.text4].filter(identity)

    return (
        <div className={props.className}>
            <Grid>
                <Cell md={5}>
                    <OneCopyAndThreeColumnsLeftText collapseMargin>
                        {props.text}
                    </OneCopyAndThreeColumnsLeftText>
                </Cell>

                <Cell md={7}>
                    <Grid>
                        {columnTexts.map((columnText, key) => (
                            <Cell xs={12 / Math.max(1, columnTexts.length)} key={key}>
                                <Body2 collapseMargin style={{ marginBottom: '1.5 rem' }}>
                                    {columnText}
                                </Body2>
                            </Cell>
                        ))}
                    </Grid>
                </Cell>
            </Grid>
        </div>
    )
}

const variants = {
    'one-copy': OneCopySection,
    'one-big-copy': OneBigCopySection,
    'two-big-copies': TwoBigCopiesSection,
    'one-copy-and-three-columns': OneCopyAndThreeColumns,
}

const baseSectionProps = {
    'one-copy': {
        marginTop: MARGINS.small,
        marginBottom: MARGINS.large,
    },
    'one-big-copy': {
        marginTop: MARGINS.xlarge,
        marginBottom: MARGINS.xxlarge,
    },
    'two-big-copies': {
        marginTop: MARGINS.xlarge,
        marginBottom: MARGINS.xlarge,
        columns: 2,
    },
    'one-copy-and-three-columns': {
        margin: true,
        marginTop: MARGINS.small,
        marginBottom: MARGINS.small,
    },
}

const TextSection = (props) => {
    const { variant } = props

    const Component = get(variants, kebabCase(variant), OneCopySection)
    const variantBaseSectionProps = get(baseSectionProps, kebabCase(variant), {})

    return (
        <BaseTextSection
            {...props}
            {...mapValues(variantBaseSectionProps, (value, key) => props[key] || value)}
        >
            <Component {...props} />
        </BaseTextSection>
    )
}

export default styled(TextSection)``

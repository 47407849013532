import React, { Fragment } from 'react'
import styled from 'styled-components'

import { marginMixin } from '../theme'

import { flatten, zip, times } from 'lodash-es'

import Container from './container'
import Section from './section'

import Divider from './Divider'

import EntriesSection from '../sections/EntriesSection'
import ImageSection from '../sections/ImageSection/ImageSection'
import PortfolioSection from '../sections/PortfolioSection/PortfolioSection'
import TextSection from '../sections/TextSection/TextSection'
import VideoSection from '../sections/VideoSection/VideoSection'
import SpacerSection from '../sections/SpacerSection/SpacerSection'

const mapping = {
    ContentfulSectionEntries: EntriesSection,
    ContentfulSectionImage: ImageSection,
    ContentfulSectionText: TextSection,
    ContentfulSectionProjects: PortfolioSection,
    ContentfulSectionVideo: VideoSection,
    ContentfulSectionSpacer: SpacerSection,
}

const InvalidSection = ({ type }) => (
    <Section>
        <span style={{ color: 'red', fontSize: '3em' }}>### Invalid section type "{type}" ###</span>
    </Section>
)

export const SectionStack = styled(
    ({ className, style, sections = [], margin = false, globals = {}, withDividers = false }) => {
        const children = sections.map((section, index) => {
            const Section = mapping[section.type] || InvalidSection

            return (
                <Fragment key={index * 2 - 1}>
                    {withDividers && index > 0 && <Divider />}
                    <Section {...section} globals={globals} />
                </Fragment>
            )
        })

        return (
            <div className={className} {...{ style }}>
                {children}
            </div>
        )
    }
)`
    ${marginMixin()}

    ${({ margin }) =>
        margin
            ? `
    `
            : `

      & > :first-child {
        margin-top: 0;
      }
    
      & > :last-child {
        margin-bottom: 0;
      }

    `}
`

export default SectionStack

import React, { useState } from 'react'
import styled from 'styled-components'

import { Image } from '../../components/Image'

import Section from '../../components/section'

import { Heading3, Subtitle, Body1, Body2, Title } from '../../components/Text'
import { onDesktop } from '@src/utils/responsive'

import { get, pick } from 'lodash-es'

const EntryImage = styled(Image)`
    & > :first-child {
        padding-bottom: 65.1% !important;
    }
`

const Entry = styled.div`
    width: 100%;
    margin-bottom: 4.9rem;

    &:last-child {
        margin-bottom: 0;
    }

    ${onDesktop`
    width: calc(50% - 14.1rem * 0.5);
    margin-bottom: 10.5rem;

    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  `}
`

const EntryGrid = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${onDesktop`
    margin-bottom: -13.7rem;
  `}
`

const EntryGridSection = (props) => {
    const { marginTop = 'small', marginBottom = 'large' } = props

    return (
        <Section className={props.className} margin {...{ marginTop, marginBottom }}>
            <EntryGrid>
                {(props.entries || []).map((entry, index) => (
                    <Entry key={index}>
                        <EntryImage fluid={get(entry, 'image.fluid')} />
                        <Heading3 style={{ marginBottom: 0 }}>
                            {entry.name + ' '}
                            <Subtitle inline>{entry.title}</Subtitle>
                        </Heading3>
                    </Entry>
                ))}
            </EntryGrid>
        </Section>
    )
}

export default styled(EntryGridSection)``

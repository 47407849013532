import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { get, first, kebabCase, mapValues, isFunction } from 'lodash-es'

import { MARGINS } from '../../theme'

import { Grid, Cell } from '../../components/grid'
import InvalidSection from '../InvalidSection/InvalidSection'
import { Media } from '../../components/Media'
import { BeforeAfterSection } from './BeforeAfterSection'
import { BaseImageSection } from './BaseImageSection'
import { useResponsiveContext } from '../../utils/responsive'

const FullwidthImageSection = ({ className, images = [] }) => (
    <div className={className}>
        <Media {...first(images)} />
    </div>
)

const ImageGridSection = ({
    images = [],
    stackOnMobile,
    marginLeft,
    marginRight,
    marginBottom,
    marginTop,
    textSize,
    textVerticalAlignment,
    textPosition,
    showTitle,
    ...props
}) => {
    const columnSize = images.length == 1 ? 12 : 6

    return (
        <div {...props}>
            <Grid>
                {images.map((media, index) => (
                    <Cell
                        {...(stackOnMobile
                            ? {
                                  xs: 12,
                                  sm: columnSize,
                              }
                            : {
                                  xs: columnSize,
                              })}
                        key={index}
                        style={{ position: 'relative' }}
                    >
                        <Media {...media} />
                    </Cell>
                ))}
            </Grid>
        </div>
    )
}

const variants = {
    'fullwidth-image': FullwidthImageSection,
    'image-grid': ImageGridSection,
    'after-before-comparison': BeforeAfterSection,
}

const baseSectionPropsDesktop = {
    'fullwidth-image': { margin: true, fullwidth: true },
    'image-grid': { marginTop: MARGINS.small, marginBottom: MARGINS.small },
    'after-before-comparison': {
        margin: true,
        marginTop: MARGINS.large,
        marginBottom: MARGINS.large,
    },
}

const baseSectionPropsMobile = {
    'fullwidth-image': {
        margin: true,
        fullwidth: true,
        style: {
            margin: '6.5rem 0',
        },
    },
    'image-grid': {
        style: ({ images, ...props }) =>
            images?.length == 1
                ? {
                      marginTop: '6.5rem',
                      marginBottom: '6.5rem',
                  }
                : {
                      padding: '0 4.4rem',
                      marginTop: '6.5rem',
                      marginBottom: '6.5rem',
                  },
    },
    'after-before-comparison': {
        style: {
            padding: '0 2.9rem',
            margin: '5rem 0 5rem 0',
        },
    },
}

const ImageSection = (props) => {
    const { isMobile } = useResponsiveContext()
    const { variant, images } = props

    const Component = get(variants, kebabCase(variant), InvalidSection)
    const variantBaseSectionProps = get(
        isMobile ? baseSectionPropsMobile : baseSectionPropsDesktop,
        kebabCase(variant),
        {}
    )

    return (
        <BaseImageSection
            {...props}
            {...mapValues(
                variantBaseSectionProps,
                (value, key) => props[key] || (isFunction(value) ? value(props) : value)
            )}
        >
            <Component {...props} images={images || []} />
        </BaseImageSection>
    )
}

export default styled(ImageSection)``

import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import moment from 'moment'
import momentTimezone from 'moment-timezone'

import { typeBody20 } from '../components/typography'

import { Heading2, Text } from '../components/Text'
import get from 'lodash/get'

import { onDesktop, onMobile, desktopQuery, mobileQuery } from '@src/utils/responsive'
import { times } from 'lodash'
import { useInViewport } from '../utils/in-viewport'

const Clock = styled.div`
    box-sizing: border-box;

    width: 11.4rem;
    height: 11.4rem;

    @media ${desktopQuery} {
        width: 14.1rem;
        height: 14.1rem;
    }

    border: 1px solid white;
    overflow: hidden;
    border-radius: 100%;
    position: relative;

    transition: background-color 0.3s ease, border-color 0.3s ease;

    background-color: ${({ variant }) => (variant == 'day' ? 'white' : 'transparent')};

    & > * {
        background-color: ${({ variant }) => (variant == 'day' ? 'black' : 'white')} !important;
    }

    &:after {
        position: absolute;
        content: '';
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ variant }) => (variant == 'day' ? 'black' : 'white')};

        border-radius: 100%;

        transition: background-color 0.3s ease;

        width: 0.6rem;
        height: 0.6rem;
    }
`

const ClockNeedle = styled.div`
    transform-origin: 0rem 0rem;

    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--white);

    transition: background-color 0.3s ease;
    border-radius: 0.3rem;
    outline: 1px solid transparent;
    ${({ type }) =>
        type == 'hour'
            ? css`
                  height: 0.3rem;
                  width: 38%;
                  transform: rotate(${({ progress }) => 360 * progress - 90}deg)
                      translate3d(-1rem, -0.15rem, 1px);
              `
            : type == 'minute'
            ? css`
                  height: 0.3rem;
                  width: 46%;
                  transform: rotate(${({ progress }) => 360 * progress - 90}deg)
                      translate3d(-1rem, -0.15rem, 1px);
              `
            : css`
                  transform: rotate(${({ progress }) => 360 * progress - 90}deg)
                      translate3d(-1rem, -0.1rem, 1px);
                  height: 0.2rem;
                  width: 52%;
              `}
`

const ClockTick = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0rem 0rem;
    background-color: var(--white);

    transition: background-color 0.3s ease;

    outline: 1px solid transparent;

    font-size: 1rem;

    @media ${mobileQuery} {
        font-size: 0.82rem;
    }

    ${({ type }) =>
        type == 'hour'
            ? css`
                  width: 1.4em;
                  height: 0.2em;
                  transform: rotate(${({ index }) => 360 * (index / 60)}deg)
                      translate3d(5.2em, -0.2em, 1px);
              `
            : css`
                  width: 0.5em;
                  height: 0.1em;
                  transform: rotate(${({ index }) => 360 * (index / 60)}deg)
                      translate3d(6em, -0.2em, 1px);
              `}
`

const ClockContainer = styled.div`
    margin-right: 2.8rem;
    @media ${desktopQuery} {
        margin-right: 8rem;
    }
`

const TextContent = styled.div`
    width: 60%;
`

const TextAddress = styled(Text)`
    ${typeBody20}
    @media ${mobileQuery} {
        margin-top: 1.1rem;
        line-height: 2rem;
    }
    p:first-child {
        margin-top: 16px;
    }
`

export const OfficeEntry = styled((props) => {
    const [entryRef, { appeared }] = useInViewport()
    const [now, setNow] = useState(moment().tz(props.timezone))

    useEffect(() => {
        const intervalId = setInterval(
            () => setNow(moment().tz(props.timezone)),
            1 /* seconds */ * 1000
        )
        return () => clearInterval(intervalId)
    })

    const second = parseInt(now.tz(props.timezone).format('ss'))
    const minute = parseInt(now.tz(props.timezone).format('mm')) + second / 60
    const hour = parseInt(now.tz(props.timezone).format('HH')) + minute / 60

    return (
        <div ref={entryRef} {...props} style={{ opacity: appeared ? 1 : 0 }}>
            <ClockContainer>
                <Clock variant={hour >= 7 && hour <= 19 ? 'day' : 'night'}>
                    <ClockNeedle progress={hour / 12} type="hour" />
                    <ClockNeedle progress={minute / 60} type="minute" />
                    <ClockNeedle progress={second / 60} type="second" />

                    {times(60).map((index) => (
                        <ClockTick
                            type={index % 5 == 0 ? 'hour' : 'minute'}
                            key={index}
                            index={index}
                        />
                    ))}
                </Clock>
            </ClockContainer>
            <TextContent>
                <Heading2 style={{ margin: 0, lineHeight: '1em' }} unveil>
                    {props.title}
                </Heading2>
                <TextAddress collapseMargin>{props.address}</TextAddress>
            </TextContent>
        </div>
    )
})`
    display: flex;

    transition: opacity 0.6s ease;

    opacity: 0;
`

export default OfficeEntry

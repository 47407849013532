import React, { useState } from 'react'
import styled from 'styled-components'

import EntryGridSection from './EntryGridSection'
import EntriesWithTextSection from './EntriesWithTextSection'

const EntriesSection = props => {
  const { text } = props

  return text ? (
    <EntriesWithTextSection {...props} />
  ) : (
    <EntryGridSection {...props} />
  )
}

export default styled(EntriesSection)``

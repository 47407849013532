import React from 'react'


const PlayIconSolid = (props) =>  
  <svg   
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.84375 19.843751"
    height="75"
    width="75"
    style={{ transformOrigin: '50% 50%'}}
  >
    <g
      transform="translate(0,-277.15624)"
    >
      <path
        d="M 2.6458334,277.15625 19.843752,287.07812 2.6458334,297 Z"
      />
    </g>
  </svg>


export default PlayIconSolid
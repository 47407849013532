import React from 'react'
import { omit, keys, first, pick, get } from 'lodash-es'
import styled from 'styled-components'

import { desktopQuery, mobileQuery } from '@src/utils/responsive'

const baseSize = 60

const sizeModifiers = {
    none: 0 / 60,
    xxsmall: 8 / 60,
    xsmall: 15 / 60,
    small: 30 / 60,
    medium: 60 / 60,
    large: 90 / 60,
    xlarge: 120 / 60,
}

const desktopBaseSize = 15
const mobileBaseSize = 2.7

const sizes = keys(sizeModifiers)

export const Spacer = styled(
    ({
        aggregate,
        color,
        horizontal,
        desktopOnly,
        mobileOnly,
        mobileSize,
        desktopSize,
        ...props
    }) => {
        const size = first(keys(pick(props, sizes)))
        const sizeModifier = get(sizeModifiers, size, sizeModifiers.medium)

        const baseSize = desktopBaseSize

        const property = `${aggregate ? 'padding' : 'margin'}${horizontal ? 'Left' : 'Top'}`

        const style = {
            [property]: `${(1 * sizeModifier).toFixed(2)}em`,
            ...props.style,
        }

        if (color) {
            style.backgroundColor = `var(--${color})`
        }

        return <div {...omit(props, sizes)} style={style} />
    }
)`
    font-size: ${({ mobileSize, size = mobileBaseSize }) => mobileSize || size}rem;

    ${({ horizontal }) => horizontal && 'display: inline-block;'}

    @media ${mobileQuery} {
        ${({ desktopOnly }) => (desktopOnly ? 'display: none;' : '')}

        ${({ xsmall, xxsmall }) =>
            xsmall || xxsmall
                ? `font-size: ${({ mobileSize, size = desktopBaseSize }) => mobileSize || size}rem;`
                : ''}
        
    }

    @media ${desktopQuery} {
        font-size: ${({ desktopSize, size = desktopBaseSize }) => desktopSize || size}rem;
        ${({ mobileOnly }) => (mobileOnly ? 'display: none;' : '')}
    }
`

Spacer.propTypes = {}

import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { typeBody24, typeHeading40 } from '../../components/typography'
import { Text } from '../../components/Text'
import { get, capitalize, isEmpty } from 'lodash-es'

import { onDesktop } from '@src/utils/responsive'

import { Grid, Cell } from '../../components/grid'
import { Media } from '../../components/Media'

const BeforeAfterComparisonSectionTextCell = styled(Cell)`
    ${onDesktop`
    ${({ position }) => (position == 'left' ? `padding-left: 12rem` : `padding-right: 13.5rem`)}
  `}
`

const BeforeAfterComparisonSectionImageCell = styled(Cell)`
    ${onDesktop`
    ${({ aside }) =>
        aside ? (aside == 'left' ? `padding-right: 7rem` : `padding-left: 7rem`) : ``}
  `}
`

const BeforeAfterComparisonSectionImageWrapper = styled.div`
    position: relative;
    padding-bottom: 70.6%;

    > * {
        position: absolute !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    > :last-child {
        opacity: ${({ visibleImage }) => (visibleImage == 'before' ? 0 : 1)};
        transition: opacity 0.15s ease;
    }
`

const BeforeAfterComparisonText = styled(Text)`
    margin: 0;
    ${typeHeading40}

    ${({ position }) =>
        position == 'left'
            ? onDesktop`
      padding-right: 4.0rem;

    `
            : onDesktop`
      padding-left: 4.0rem;
      
    `}
`

const BeforeAfterComparisonSwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 0.7rem 0 0 0;

    ${onDesktop`
    margin: 2.5em 0;
  `}
`

const BeforeAfterComparisonSwitch = styled.div`
    display: inline-block;

    width: 4em;
    height: 1.8em;
    border: 1px solid black;
    margin-right: 1em;

    ${onDesktop`
    width: 5.1em;
    height: 2.4em;
    border: 2px solid black;
    margin-right: 2.5em;
  `}

    border-radius: 1.6em;
    position: relative;

    cursor: pointer;

    &:after {
        transition: left 0.15s ease;

        position: absolute;
        top: 50%;

        transform: translateY(-50%);
        display: block;
        content: '';
        background-color: black;
        border-radius: 100%;

        left: calc(${({ visibleImage }) => (visibleImage == 'before' ? '0%' : '100% - 2.0em')});
        margin: 0 0.3em;
        width: 1.4em;
        height: 1.4em;

        ${onDesktop`
      left: calc(${({ visibleImage }) => (visibleImage == 'before' ? '0%' : '100% - 2.6em')});
      margin: 0 0.3em;
      width: 1.9em;
      height: 1.9em;
    `}
    }
`

const BeforeAfterComparisonSwitchText = styled.span`
    ${typeBody24}
    ${onDesktop`
    line-height: 0;
  `}
`

export const BeforeAfterSection = styled(
    ({ className, images = [], text, text2, textPosition }) => {
        const [visibleImage, setVisibleImage] = useState('after')

        const hasText = !isEmpty(text) || !isEmpty(text2)
        textPosition = (textPosition || '').toLowerCase()

        return (
            <div className={className}>
                <Grid align={'center'} reverse={hasText && textPosition == 'left'}>
                    <BeforeAfterComparisonSectionImageCell
                        xs={12}
                        sm={hasText ? 6 : 8}
                        aside={hasText ? (textPosition == 'left' ? 'right' : 'left') : false}
                    >
                        <BeforeAfterComparisonSectionImageWrapper visibleImage={visibleImage}>
                            <Media {...get(images, '0')} />
                            <Media {...get(images, '1')} />
                        </BeforeAfterComparisonSectionImageWrapper>

                        <BeforeAfterComparisonSwitchWrapper>
                            <BeforeAfterComparisonSwitch
                                visibleImage={visibleImage}
                                onClick={() =>
                                    setVisibleImage(visibleImage == 'after' ? 'before' : 'after')
                                }
                            />

                            <BeforeAfterComparisonSwitchText>
                                {capitalize(visibleImage)}
                            </BeforeAfterComparisonSwitchText>
                        </BeforeAfterComparisonSwitchWrapper>
                    </BeforeAfterComparisonSectionImageCell>

                    {hasText ? (
                        <BeforeAfterComparisonSectionTextCell
                            xs={12}
                            sm={6}
                            position={textPosition}
                        >
                            {text && (
                                <BeforeAfterComparisonText position={textPosition} collapseMargin>
                                    {text}
                                </BeforeAfterComparisonText>
                            )}

                            {text2 && (
                                <BeforeAfterComparisonText position={textPosition}>
                                    {text2}
                                </BeforeAfterComparisonText>
                            )}
                        </BeforeAfterComparisonSectionTextCell>
                    ) : null}
                </Grid>
            </div>
        )
    }
)`
    font-size: 10px;
`

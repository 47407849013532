import React from 'react'

import Section from '../../components/section'

import { pick } from 'lodash-es'

const InvalidSection = ({ variant, marginTop, marginBottom }) => {
  variant = variant || 'undefined'

  console.error('Invalid image section variant selected:', variant)

  return (
    <Section margin {...{ marginTop, marginBottom }}>
      Invalid section variant "{variant}"
    </Section>
  )
}

export default InvalidSection

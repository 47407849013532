import React from 'react'


const SoundOnIcon = (props) =>  
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="8 0 241.94 219.46"
    {...props}
  >
    <path 
      d="M123.55,56.94a6.51,6.51,0,0,0-9.18-.92L83.62,81a4.71,4.71,0,0,0-.53,0H65.21a6.57,6.57,0,0,0-6.55,6.55v44.42a6.56,6.56,0,0,0,6.55,6.54H83.09l.52,0,30.73,25a6.58,6.58,0,0,0,4.12,1.48,6.68,6.68,0,0,0,6.57-6.59V61.08A6.52,6.52,0,0,0,123.55,56.94Zm-42.77,72.7H67.51V89.82H80.78Zm35.41,23.9L89.63,132V87.5l26.55-21.57Z"
    />
    <path 
      d="M147.2,80.26,142,87.43a27,27,0,0,1,10.29,21.84,26.54,26.54,0,0,1-11.24,22.5l4.78,7.46a35.39,35.39,0,0,0,15.28-29.95A35.52,35.52,0,0,0,147.2,80.26Z"
    />
    <path 
      d="M165.3,71.41l-5.2,7.14a37.44,37.44,0,0,1,14.33,30.57,37.17,37.17,0,0,1-15.68,31.5l4.8,7.44a46.15,46.15,0,0,0,19.72-38.95A46.28,46.28,0,0,0,165.3,71.41Z"
    />
  </svg>

export default SoundOnIcon
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { Image } from '../../components/Image'

import Section from '../../components/section'

import { Text } from '../../components/Text'

import OfficeEntry from '../../entries/OfficeEntry'

import { MARGINS } from '../../theme'
import { onDesktop, onMobile, isMobile } from '@src/utils/responsive'

import { get, pick } from 'lodash-es'
import { typeHeading50, typeHeadingXXL } from '../../components/typography'
import { Switch } from '../../components/switch'
import { desktopQuery, mobileQuery } from '../../utils/responsive'

const EntryImage = styled(Image)`
    & > :first-child {
        padding-bottom: 65.1% !important;
    }
`

const Entry = styled.div`
    width: 100%;
    @media ${mobileQuery} {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media ${desktopQuery} {
        width: 100%;
        flex-grow: 0;
        margin-bottom: 10rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

const Entries = styled.div`
    display: flex;
    flex-direction: column;

    @media ${mobileQuery} {
        order: 2;
        margin-bottom: 3.5rem;
    }

    @media ${desktopQuery} {
        width: calc(100% - 58.33%);
        padding-bottom: 8.3rem;
        padding-top: 0rem;
    }
`

const TextContent = styled(
    React.forwardRef(({ className, style, primary, secondary, ...props }, ref) => (
        <div {...{ className, style }}>
            <Text ref={ref} className="innerText" {...props} />
        </div>
    ))
)`
    ${typeHeading50}

    @media ${mobileQuery} {
        font-size: 2.2rem;
        letter-spacing: 0.03em !important;
    }

    margin-bottom: 4rem;

    @media ${desktopQuery} {
        margin-bottom: 8rem;
    }

    position: relative;

    .innerText {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &.entering {
        opacity: 0;
    }
    
    &.entered {
        transition: 0.3s ease 0.6s;
        opacity: 1;

    }

    &.exiting {
        opacity: 1;
    }
    
    &.exited {
        transition: 0.3s ease 0s;
        opacity: 0;
        pointer-events: none;

    }

    ${({ visible = true }) => (visible ? css`` : css``)};

`

const TextContentWrapper = styled.div`
    min-height: 0;

    margin-bottom: 3.4rem;

    opacity: 0;
    ${({ visible = true }) =>
        visible
            ? css`
                  transition: min-height 0.6s ease 0.3s, opacity 0.6s ease 0.3s;
                  opacity: 1;
              `
            : css`
                  opacity: 0;
              `}
`

const RightContent = styled.div`
    @media ${mobileQuery} {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
    @media ${desktopQuery} {
        width: 58.33%;
    }
`

const TextSwitch = styled(Switch)`
    margin-bottom: 4rem;

    @media ${desktopQuery} {
        margin-bottom: 8rem;
    }
`

const EntryPlaceholderTop = styled.div`
    @media ${desktopQuery} {
        width: calc(100% - 58.33%);
    }
`

const SectionRow = styled.div`
    display: flex;

    @media ${mobileQuery} {
        flex-direction: column;
    }
`

const EntriesWithTextSection = (props) => {
    const { marginTop = MARGINS.large, marginBottom = MARGINS.medium } = props

    const [initialized, setInitialized] = useState(false)

    const [textToggled, setTextToggled] = useState(false)

    const [minHeight, setMinHeight] = useState(0)

    const [transitioning, setTransitioning] = useState(false)

    const primaryTextRef = useRef()
    const secondaryTextRef = useRef()

    const updateMinHeight = () => {
        setMinHeight(
            textToggled
                ? secondaryTextRef.current?.clientHeight || 0
                : primaryTextRef.current?.clientHeight || 0
        )
    }

    useEffect(() => {
        updateMinHeight()

        setTimeout(() => setInitialized(true), 10)
    }, [primaryTextRef && primaryTextRef.current, secondaryTextRef && secondaryTextRef.current])

    useEffect(() => {
        if (!transitioning) {
            updateMinHeight()
            setTransitioning(true)
            const timeoutId = setTimeout(() => setTransitioning(false), 100)
            return () => clearTimeout(timeoutId)
        }
    }, [textToggled])

    return (
        <Section className={props.className} margin {...{ marginTop, marginBottom }}>
            <SectionRow>
                <Entries>
                    {(props.entries || []).map((entry, index) => (
                        <Entry key={index}>
                            <OfficeEntry {...entry} />
                        </Entry>
                    ))}
                </Entries>

                <RightContent>
                    {props.alternateTextSwitch && (
                        <TextSwitch
                            {...{
                                offText: props.switchPrimaryTextOptionName,
                                onText: props.switchSecondaryTextOptionName,
                                onToggled: setTextToggled,
                                defaultValue: textToggled,
                            }}
                        />
                    )}

                    <TextContentWrapper style={{ minHeight }} visible={initialized}>
                        {props.text && (
                            <TextContent
                                ref={primaryTextRef}
                                collapseMargin
                                primary
                                visible={!textToggled || !props.alternateTextSwitch}
                                className={
                                    !textToggled
                                        ? transitioning
                                            ? 'entering'
                                            : 'entered'
                                        : transitioning
                                        ? 'exiting'
                                        : 'exited'
                                }
                            >
                                {props.text}
                            </TextContent>
                        )}
                        {props.secondaryText && (
                            <TextContent
                                ref={secondaryTextRef}
                                collapseMargin
                                secondary
                                visible={textToggled || !props.alternateTextSwitch}
                                className={
                                    textToggled
                                        ? transitioning
                                            ? 'entering'
                                            : 'entered'
                                        : transitioning
                                        ? 'exiting'
                                        : 'exited'
                                }
                            >
                                {props.secondaryText}
                            </TextContent>
                        )}
                    </TextContentWrapper>

                    {props.footerText && (
                        <TextContent collapseMargin secondary>
                            {props.footerText}
                        </TextContent>
                    )}
                </RightContent>
            </SectionRow>
        </Section>
    )
}

export default styled(EntriesWithTextSection)``

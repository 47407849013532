import React, { Component, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { isEmpty, throttle, bind, noop, uniqueId } from 'lodash-es'

import { onDesktop, onMobile } from '@src/utils/responsive'

import Section from '../../components/section'

import { Player as BasePlayer } from '../../components/Player/Player'
import { Image } from '../../components/Image'
import { typeBody20, typeHeading28 } from '../../components/typography'

import SoundOnIcon from '../../icons/SoundOnIcon'
import SoundOffIcon from '../../icons/SoundOffIcon'
import PlayIconSolid from '../../icons/PlayIconSolid'
import PlayIcon from '../../icons/PlayIcon'
import PauseIconSolid from '../../icons/PauseIconSolid'

const debug = false ? bind(console.log, console, '[VideoSection]') : noop

const Player = styled(BasePlayer)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 0;

    ${onMobile`
    display: none;
    `}
`

const MobilePlayer = styled(BasePlayer)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 0;

    ${onDesktop`
      display: none;
    `}
`

const PlayerContainer = styled.div`
    position: relative;

    ${onMobile` 
    height: 100vh;
    padding-bottom: 0;
  `}

    ${onDesktop`
    padding-bottom: calc(9/16 * 100%);
  `}
`

const VideoActionFeedback = styled.div`
    ${onMobile`
    display: none;
  `}

    position: absolute;
    width: 14.42rem;
    height: 16.59rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    transition: opacity 0.15s ease;
    opacity: ${({ visible }) => (visible ? 1 : 0)};

    & > * {
        width: 100%;
        height: auto;
        transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, fill 0.15s ease 0s;
        transform: ${({ visible, intent }) =>
            intent ? 'scale(1.8, 1.8)' : visible ? 'scale(1.5, 1.5)' : 'scale(1, 1)'};
        opacity: ${({ visible, keepVisible, intent }) => (keepVisible ? 1 : visible ? 0 : 1)};

        fill: ${({ intent }) => (intent ? '#f3f3f4' : `var(--white)`)};
    }
`

const SoundToggle = styled.div`
    position: absolute;
    left: 4.7rem;
    bottom: 2.5rem;
    z-index: 2;

    opacity: ${({ visible }) => (visible ? 1 : 0)};
    transition: opacity 0.15s ease;

    width: 7rem;
    height: 7rem;

    cursor: pointer;

    .sound-on,
    .sound-off {
        transition: opacity 0.3s ease;
        opacity: 0;

        &.visible {
            transition: opacity 0.3s ease 0.15s;
            opacity: ${({ visible }) => (visible ? 1 : 0)};
        }
    }

    & > * {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        fill: var(--white);
    }

    ${onMobile`
    display: none;
  `}
`

const PosterImage = styled(Image)`
    ${onDesktop`
    transition: opacity 0.15s ease;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  `}

    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
`

const PlayReelButton = styled((props) => (
    <div {...props}>
        <PlayIcon style={{ width: '4rem', height: 'auto ' }} />
        <span> Play Reel</span>
    </div>
))`
    ${typeHeading28}

    font-weight: 500;

    padding: 1rem 1rem;

    display: flex;
    align-items: center;

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: var(--white);

    ${onDesktop`
    display: none;
  `}
`

const InteractiveOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    cursor: pointer;

    background-color: rgba(0, 0, 0, ${({ playing }) => (playing ? 0 : 0.05)});
    transition: background-color 0.3s ease;

    .sound-toggle {
        opacity: 0;
        transition: opacity 0.15s ease;
    }

    &:hover .sound-toggle {
        opacity: 1;
    }
`

const ScrollDownHint = styled((props) => {
    const delay = 3

    const [visible, setVisible] = useState(true)
    const [appeared, setAppeared] = useState(false)

    useEffect(() => {
        const handleScroll = throttle(() => {
            setVisible(window.scrollY < 100)
        }, 100)

        window.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => window.removeEventListener('scroll', handleScroll)
    }, [visible])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAppeared(true)
        }, delay * 1000)

        return () => clearTimeout(timeout)
    }, [])

    return (
        <span
            {...props}
            style={{
                opacity: appeared && visible ? 1 : 0,
                transform: `translate(-50%, ${appeared && visible ? 0 : -100}%)`,
            }}
        >
            Scroll Down
        </span>
    )
})`
    position: absolute;
    left: 50%;
    bottom: 8.4rem;
    transform: translate(-50%, 0%);
    z-index: 90;
    transition: transform 0.3s ease, opacity 0.3s ease;
    ${typeBody20}

    ${onDesktop`
    bottom: 4.4rem;
  `}
    user-select: none;
    cursor: default;
`

export class VideoSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mute: false,
            playedOnce: false,
            playing: false,
            playIntent: false,
            pauseIntent: false,
            isPreviewVideoPlaying: false,
            removePreview: false,
        }

        this.id =
            (props.id ? props.id : 'section-video-' + props.vimeoId + '-' + uniqueId()) ||
            uniqueId()

        this.videoElement = null
        this.mobileVideoElement = null
        this.previewVideoElement = null
    }

    setMobileReelVideoRef(ref) {
        if (!this.mobileVideoElement) {
            this.mobileVideoElement = ref
        }
    }

    setReelVideoRef(ref) {
        if (!this.videoElement) {
            this.videoElement = ref
        }
    }

    setPreviewVideoRef(ref) {
        if (!this.previewVideoElement) {
            this.previewVideoElement = ref
        }
    }

    onMobilePlayButtonClick(e) {
        e.stopPropagation()
        debug('Play (Mobile)')
        this.mobileVideoElement.playInFullscreen()
    }

    onInteractiveOverlayClick(e) {
        const { playing, isPreviewVideoPlaying } = this.state

        if (playing) {
            debug('Toggle Play (Desktop) - Pause')
            this.videoElement.player.pause()
            //this.setState({ pauseIntent: true})
        } else {
            debug('Toggle Play (Desktop) - Play')

            if (!isPreviewVideoPlaying) {
                this.setState({ removePreview: true })
            }

            this.setState({ playIntent: true })
            this.videoElement.player.play()
        }
    }

    onToggleMute(e) {
        e.stopPropagation()
        const isMuted = this.state.mute
        const willBeMuted = !isMuted
        this.setState({ mute: willBeMuted })
        this.videoElement.player.setVolume(willBeMuted ? 0 : 1)
    }

    onPreviewVideoPlay() {
        debug('Preview video : played')
        this.setState({ isPreviewVideoPlaying: true })
    }

    onPreviewVideoPause() {
        debug('Preview video : paused')
        this.setState({ isPreviewVideoPlaying: false })
    }

    onReelVideoPlay() {
        debug('Reel Video : played')

        if (this.previewVideoElement) {
            this.previewVideoElement.player.pause()
        }

        setTimeout(() => {
            this.setState({
                playing: true,
                playedOnce: true,
            })
        }, 100)

        this.setState({
            playIntent: false,
        })
    }

    onReelVideoPause() {
        debug('Reel Video : paused')

        setTimeout(() => this.setState({ pauseIntent: false }), 150)

        this.setState({ playing: false })
    }

    render() {
        const {
            id,
            className,
            children,
            posterImage,
            variant,
            vimeoId,
            previewVimeoId,
            playText,
            marginTop,
            marginBottom,
        } = this.props

        const {
            mute,
            playedOnce,
            playing,
            playIntent,
            pauseIntent,
            removePreview,
            isPreviewVideoPlaying,
        } = this.state
        const soundToggleVisible = playing
        const hasPreviewVideo = !isEmpty(previewVimeoId) && previewVimeoId != vimeoId
        const posterVisible = !playedOnce && !isPreviewVideoPlaying

        return (
            <Section
                id={this.id}
                className={className}
                {...{
                    marginTop: marginTop || 'none',
                    marginBottom: marginBottom || 'none',
                }}
                fullwidth
            >
                <PlayerContainer>
                    <MobilePlayer
                        ref={this.setMobileReelVideoRef.bind(this)}
                        tag={'Reel Video Mobile'}
                        vimeoId={vimeoId}
                        background
                    />

                    <Player
                        ref={this.setReelVideoRef.bind(this)}
                        tag={'Reel Video'}
                        vimeoId={vimeoId}
                        autoplay={!hasPreviewVideo}
                        muted={!hasPreviewVideo}
                        looped={!hasPreviewVideo}
                        background
                        onPlay={this.onReelVideoPlay.bind(this)}
                        onPause={this.onReelVideoPause.bind(this)}
                    />

                    {hasPreviewVideo && !removePreview && !playedOnce && (
                        <Player
                            tag={'Reel Preview'}
                            ref={this.setPreviewVideoRef.bind(this)}
                            vimeoId={previewVimeoId}
                            autoplay
                            muted
                            looped
                            background
                            onPlay={this.onPreviewVideoPlay.bind(this)}
                            onPause={this.onPreviewVideoPause.bind(this)}
                        />
                    )}

                    {posterImage && (
                        <PosterImage fluid={posterImage.fluid} visible={posterVisible} />
                    )}

                    <InteractiveOverlay
                        onClick={this.onInteractiveOverlayClick.bind(this)}
                        playing={playing}
                    >
                        <VideoActionFeedback visible={!playing} keepVisible intent={playIntent}>
                            <PlayIconSolid />
                        </VideoActionFeedback>

                        <VideoActionFeedback visible={false}>
                            <PauseIconSolid />
                        </VideoActionFeedback>

                        <SoundToggle
                            className="sound-toggle"
                            visible={soundToggleVisible}
                            onClick={this.onToggleMute.bind(this)}
                        >
                            <SoundOnIcon className={`sound-on ${mute ? 'hidden' : 'visible'}`} />
                            <SoundOffIcon className={`sound-off ${mute ? 'visible' : 'hidden'}`} />
                        </SoundToggle>
                    </InteractiveOverlay>

                    <PlayReelButton onClick={this.onMobilePlayButtonClick.bind(this)} />

                    <ScrollDownHint />
                </PlayerContainer>
            </Section>
        )
    }
}

export default VideoSection

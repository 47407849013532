import React from 'react'


const PauseIconSolid = (props) =>  
  <svg   
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.84375 19.843751"
    height="75"
    width="75"
  >
    <g
      transform="translate(0,-277.15624)"
    >
      <g
        id="g4540"
        transform="translate(0.39687492)"
      >
        <rect
          y="277.15625"
          x="2.6458335"
          height="19.84375"
          width="5.0270834"
        />
        <rect
          y="277.15625"
          x="11.377084"
          height="19.84375"
          width="5.0270834"
        />
      </g>
    </g>
  </svg>


export default PauseIconSolid


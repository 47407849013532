import { isNumber } from 'lodash'
import styled, { css } from 'styled-components'

export const PlayerRoot = styled.div`
    position: relative;

    ${({ aspectRatio }) =>
        isNumber(aspectRatio) &&
        css`
            padding-bottom: ${((1 / aspectRatio) * 100).toFixed(2)}%;
        `}

    ${({ fillParent, aspectRatio }) =>
        fillParent &&
        css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            ${!aspectRatio && 'height: 100%;'}
            width: 100%;
        `}

        
    ${({ background }) => background && 'pointer-events: none;'}
`

export const PlayerFullscreenBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    ${({ fullscreen }) =>
        fullscreen
            ? css`
                  transition: opacity 0.15s ease;
                  opacity: 1;
              `
            : css`
                  transition: opacity 0.15s ease 0.3s;
                  opacity: 0;
                  pointer-events: none;
              `}
`

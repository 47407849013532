import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { getTextSize } from '../../components/typography'
import { Text } from '../../components/Text'
import { lowerCase } from 'lodash-es'

import { onDesktop, onMobile, isMobile } from '@src/utils/responsive'

import Section from '../../components/section'

import { desktopQuery, mobileQuery } from '../../utils/responsive'

const ImageContent = styled.div``

const TextContent = styled(({ size, verticalAlignment, position, showTitle, ...props }) => (
    <div {...props} />
))`
  box-sizing: border-box;
  ${({ size }) => getTextSize(size || '40')}
  margin: 0;

  ${({ verticalAlignment }) =>
      verticalAlignment &&
      css`
          display: flex;
          align-items: ${{ Top: 'flex-start', Center: 'center', Bottom: 'flex-end' }[
              verticalAlignment
          ]};
      `}
   
    @media ${mobileQuery} {   

        margin-top: 3.5rem;

        & > :first-child {
            margin-top: 0;
        }

        & > * {
            margin-bottom: 2.7rem;
        }

        & > :last-child {
            margin-bottom: 4rem;
        }
    }

    @media ${desktopQuery} {   
        & > * {
            margin-bottom: 4rem;
        }
        
        ${({ position }) =>
            lowerCase(position) == 'left'
                ? css`
                      padding-right: 12.5rem;
                  `
                : css`
                      padding-left: 12.5rem;
                  `}
    }
  
  

`

const baseImageSectionWithTextMixin = css`
    display: flex;

    ${onMobile`
    flex-direction: ${({ textPosition }) =>
        lowerCase(textPosition) == 'right' ? 'column-reverse' : 'column'};
  `}

    ${onDesktop`
    flex-direction: ${({ textPosition }) =>
        lowerCase(textPosition) == 'right' ? 'row-reverse' : 'row'};

    & > :first-child {
      width: ${(5 / 12) * 100}%;
    }

    & > * {
      width: ${(7 / 12) * 100}%;
    }
  `}
`

const BaseImageSectionContent = styled(({ text, textPosition, textSize, ...props }) => (
    <div {...props} />
))`
    ${({ text }) => (text ? baseImageSectionWithTextMixin : '')}
`

const SecondaryText = styled(Text)`
    @media ${mobileQuery} {
        font-size: 2.2rem;
        line-height: 3rem;
    }
`

export const BaseImageSection = styled(
    ({
        className,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fullwidth,
        text,
        text2,
        title,
        showTitle,
        textPosition,
        textVerticalAlignment,
        textSize,
        children,
        style,
    }) => (
        <Section
            className={className}
            margin
            {...{ marginTop, marginBottom, marginLeft, marginRight, style }}
            fullwidth={fullwidth}
            animation="fade-in-up"
        >
            <BaseImageSectionContent {...{ text, text2, textPosition }}>
                {(text || text2) && (
                    <TextContent
                        {...{
                            showTitle,
                            size: textSize,
                            position: textPosition,
                            verticalAlignment: textVerticalAlignment,
                        }}
                        count={text && text2 ? 2 : 1}
                    >
                        {showTitle && title && <Text collapseMargin>{title}</Text>}
                        {text && <Text collapseMargin>{text}</Text>}

                        {text2 && <SecondaryText collapseMargin>{text2}</SecondaryText>}
                    </TextContent>
                )}
                <ImageContent>{children}</ImageContent>
            </BaseImageSectionContent>
        </Section>
    )
)``

import { throttle } from 'lodash'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { onDesktop, onMobile, desktopQuery, mobileQuery } from '@src/utils/responsive'
import { typeBody24, typeHeading24, typeHeading50 } from './typography'

const Toggle = styled.div`
    border: 1px solid white;
    border-radius: 2rem;
    height: 1.7rem;
    width: 3.3rem;

    margin: 0 2.7rem;

    @media ${desktopQuery} {
        height: 3.4rem;
        width: 7rem;
        margin: 0 4.7rem;
    }

    position: relative;

    &:after {
        position: absolute;
        border-radius: 100%;
        background: white;

        left: ${1.8 * (1.2 / 2.6)}rem;
        width: 1.2rem;
        height: 1.2rem;

        @media ${desktopQuery} {
            width: 2.6rem;
            height: 2.6rem;
            left: 1.8rem;
        }

        top: 50%;
        bottom: 0;
        content: '';
        display: block;
        transform: translate(-50%, -50%);

        transition: left 0.3s ease;

        ${({ value }) =>
            value
                ? css`
                      left: calc(100% - ${1.8 * (1.2 / 2.6)}rem);
                      @media ${desktopQuery} {
                          left: calc(100% - 1.8rem);
                      }
                  `
                : css``}
    }

    ${({ value }) => (value ? css`` : css``)}

    cursor: pointer;
`

const Label = styled.div`
    ${typeBody24}
    display: flex;
    align-items: center;
    position: relative;
    top: -0.1em;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`

export const Switch = ({
    defaultValue = false,
    onToggled,
    offText = 'Off',
    onText = 'On',
    ...props
}) => {
    const [value, setValue] = useState(defaultValue)

    const onClick = throttle((event) => {
        setValue(!value)
        onToggled(!value)
    }, 100)

    return (
        <Wrapper {...props}>
            <Label>{offText}</Label>
            <Toggle value={value} onClick={onClick} />
            <Label>{onText}</Label>
        </Wrapper>
    )
}

import React from 'react'

import Section from '../../components/section'

import { pick, kebabCase } from 'lodash-es'
import { Spacer } from '../../components/Spacer'

const SpacerSection = ({ size, ...props }) => {
    return <Spacer size={kebabCase(size)} />
}

export default SpacerSection
